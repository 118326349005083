import { useEffect, useState } from 'react';
import './Loader.css';
import logo from '../../Assets/images/logo-no-text.svg';
import { APPNAME, DELAYANIMATION } from '../../Utilities/Constans';

const Loader = () => {
  const [visibleLetters, setVisibleLetters] = useState<string[]>([]);

  useEffect(() => {
    startAnimation();
  }, []);

  const startAnimation = () => {
    let index = -2;
    let isAppearing = true;

    const animationStep = () => {
      if (isAppearing) {
        if (index < APPNAME.length + 1) {
          setVisibleLetters(prevLetters => {
            const updatedLetters = [...prevLetters];
            updatedLetters[index] = APPNAME.charAt(index);
            return updatedLetters;
          });
          index++;
          setTimeout(animationStep, DELAYANIMATION);
        } else {
          isAppearing = false;
          index--;
          setTimeout(animationStep, DELAYANIMATION);
        }
      } else {
        if (index >= 0) {
          setVisibleLetters(prevLetters => {
            const updatedLetters = [...prevLetters];
            updatedLetters[index] = '';
            return updatedLetters;
          });
          index--;
          if (index < 0) {
            isAppearing = true;
          }
          setTimeout(animationStep, DELAYANIMATION);
        }
      }
    };
    animationStep();
  };

  return (
    <>
      <div className="loaderContainer">
        <div className="loaderLogoContainer">
          <img alt="logo" src={logo} className="loaderLogo"></img>
          <div className="loaderLogoText">
            {APPNAME.split('').map((letter, index) => (
              <span key={index} className={`letter ${visibleLetters.includes(letter) ? 'showLetter' : ''}`}>
                {letter}
              </span>
            ))}
          </div>
        </div>
        <h1>Aguarde un instante</h1>
      </div>
    </>
  );
};

export default Loader;
