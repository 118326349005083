import { YPOINTER_REFERENCE } from './Constans';

export const sliceTextOutPut = (text: string): string => {
  if (text.length > 0) return text.slice(0, -1);
  return text;
};

export const clearTextOutPut = (text: string): string => {
  if (text.length > 0) return (text = '');
  return text;
};

export const addTextOutPut = (prevText: string, text: string): string => {
  //ESTA FUNCION NO PUEDE SER IMPLEMENTADA AUN
  if (prevText.length > 0) return prevText + text.toLowerCase();
  return prevText + text;
};

export const restoreLeftSection = (initialleters: string[]): string[] => {
  return initialleters.slice(0, Math.ceil(initialleters.length / 2));
};

export const restoreRightSection = (initialleters: string[]): string[] => {
  return initialleters.slice(Math.ceil(initialleters.length / 2));
};

export const calculateMaxYPointEvent = (point: number): number => {
  return Number((point + YPOINTER_REFERENCE).toFixed(4));
};

export const addLetterToCommands = (commands: string[], letter: string): string[] => {
  return commands.concat(letter);
};
