import { Gestures, TGesstureScores, useDetectionStore } from '../../Models/useDetectionStore';
import './GestureFeedback.css';
/**
 * Componente `GestureFeedBack`
 *
 * Este componente se encarga de mostrar una retroalimentación visual basada en los puntajes de gestos detectados.
 * Utiliza los puntajes de gestos de mirar hacia arriba, abajo, izquierda y derecha para ajustar el color del borde
 * del div principal. Los colores se ajustan en función de la intensidad con la que se detectan estos gestos.
 *
 * Props:
 * - No recibe props directamente, pero utiliza el hook `useDetectionStore` para obtener los puntajes de los gestos.
 *
 * Estilos:
 * - Los estilos se manejan principalmente a través del archivo `GestureFeedBack.css`.
 *
 * Uso:
 * - Se utiliza dentro del componente `Home` para proporcionar una interfaz visual que refleje los gestos detectados
 *   por el usuario en tiempo real.
 */

const GestureFeedBack = () => {
  const { gestures } = useDetectionStore();

  const calcStyleColor = (gestures: TGesstureScores) => {
    return `rgba(255,255,255,${gestures.lookUpScore}) rgba(255,255,255,${gestures.lookRightScore}) rgba(255,255,255,${gestures.lookDownScore}) rgba(255,255,255,${gestures.lookLeftScore})`;
  };
  return (
    <div id="gesture-feedback" style={{ borderColor: calcStyleColor(gestures.scores) }}>
      {gestures.active.lookUpScore && <div className="marker look-up"></div>}
      {gestures.active.lookDownScore && <div className="marker look-down"></div>}
      {gestures.active.lookLeftScore && <div className="marker look-left"></div>}
      {gestures.active.lookRightScore && <div className="marker look-right"></div>}
    </div>
  );
};

export default GestureFeedBack;
