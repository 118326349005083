import React, { useState } from 'react';
import './OptionsMenu.css'; // Asegúrate de crear este archivo CSS para estilos

import { ReactComponent as ClosedMenuButton } from '../../Assets/icons/sliders-svgrepo-com.svg';
import { ReactComponent as OpenMenuButton } from '../../Assets/icons/x-circle-svgrepo-com.svg';

const OptionsMenu = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="options-menu-wrapper">
      <button className={`menu-button ${menuVisible ? 'pressed' : ''}`} onClick={toggleMenu}>
        {menuVisible ? <OpenMenuButton className="menu-button-icon" /> : <ClosedMenuButton className="menu-button-icon" />}
      </button>
      {menuVisible && (
        <div className="options-menu">
          <button onClick={() => console.log('Alternar pantalla completa')}>
            Pantalla completa
            <span className="tooltip">Alternar el modo de pantalla completa</span>
          </button>
          <button onClick={() => console.log('Ir al tutorial')}>
            Tutorial
            <span className="tooltip">Ver el tutorial de uso</span>
          </button>
          <button onClick={() => console.log('Cerrar sesión')}>
            Logout
            <span className="tooltip">Cerrar la sesión actual</span>
          </button>
          <button onClick={() => console.log('Cargar página')}>
            Cargar página
            <span className="tooltip">Recargar la página actual</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default OptionsMenu;
