import { Dispatch } from 'redux';
import { Action, EventGestures } from '../../Models/Redux';
export const SET_LETTERS = 'SET_LETTERS';
export const RESTORE_LETTERS = 'RESTORE_LETTERS';
export const SET_READY_TO_WRITE = 'SET_READY_TO_WRITE';
export const SET_LEFT_LETTERS = 'SET_LEFT_LETTERS';
export const SET_RIGHT_LETTERS = 'SET_RIGHT_LETTERS';
export const SET_BYPASS = 'SET_BYPASS';
export const SET_COMMANDS = 'SET_COMMANDS';
export const SET_LETTERS_TO_COMMANDS = 'SET_LETTERS_TO_COMMANDS';
export const RESTORE_COMMANDS = 'RESTORE_COMMANDS';
export const SET_LEFT_COMMANDS = 'SET_LEFT_COMMANDS';
export const SET_RIGHT_COMMANDS = 'SET_RIGHT_COMMANDS';
export const CLEAN_SECTION_LETTERS = 'CLEAN_SECTION_LETTERS';
export const SET_ON_LAST_X_EVENT = 'SET_ON_LAST_X_EVENT';
export const SET_ON_NEW_X_EVENT = 'SET_ON_NEW_X_EVENT';
export const SET_ON_LAST_Y_EVENT = 'SET_ON_LAST_Y_EVENT';
export const SET_ON_NEW_Y_EVENT = 'SET_ON_NEW_Y_EVENT';
export const SET_ON_LAST_XY_EVENT = 'SET_ON_LAST_XY_EVENT';
export const SET_ON_NEW_XY_EVENT = 'SET_ON_NEW_XY_EVENT';
export const SET_IRISH_X_POINT = 'SET_IRISH_X_POINT';
export const SET_IRISH_Y_POINT = 'SET_IRISH_Y_POINT';
export const SET_REFERENCE_IRISH_Y_POINT = 'SET_REFERENCE_IRISH_Y_POINT';
export const SET_RESTORE_REFERENCE_IRISH_Y_POINT = 'SET_RESTORE_REFERENCE_IRISH_Y_POINT';
export const SET_OUT_OF_RANGE = 'SET_OUT_OF_RANGE';
export const SET_TEXT_OUTPUT = 'SET_TEXT_OUTPUT';
export const REMOVE_TEXT_OUTPUT = 'REMOVE_TEXT_OUTPUT';
export const CLEAR_TEXT_OUTPUT = 'CLEAR_TEXT_OUTPUT';
export const SET_SERVICE_STATUS = 'SET_SERVICE_STATUS';
export const SET_ONLOADER_STATUS = 'SET_ONLOADER_STATUS';

export const setLetters = (response: string[]) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_LETTERS, payload: response });
  };
};

export const setRestoreLetters = () => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: RESTORE_LETTERS });
  };
};

export const setReadyToWrite = (response: boolean) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_READY_TO_WRITE, payload: response });
  };
};

export const setLeftSection = (response: string[]) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_LEFT_LETTERS, payload: response });
  };
};

export const setRightSection = (response: string[]) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_RIGHT_LETTERS, payload: response });
  };
};

export const setCleanSectionLetters = () => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: CLEAN_SECTION_LETTERS });
  };
};

export const setBypass = (response: boolean) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_BYPASS, payload: response });
  };
};

export const setCommands = (response: string[]) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_COMMANDS, payload: response });
  };
};

export const setLettersToCommands = (response: string[]) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_LETTERS_TO_COMMANDS, payload: response });
  };
};

export const setRestoreCommands = () => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: RESTORE_COMMANDS });
  };
};

export const setCommandsLeftSection = (response: string[]) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_LEFT_COMMANDS, payload: response });
  };
};

export const setCommandsRightSection = (response: string[]) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_RIGHT_COMMANDS, payload: response });
  };
};

export const setOnLastXEvent = async (response: EventGestures): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_ON_LAST_X_EVENT, payload: response });
  };
};

export const setOnNewXEvent = async (response: EventGestures): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_ON_NEW_X_EVENT, payload: response });
  };
};

export const setOnLastYEvent = async (response: EventGestures): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_ON_LAST_Y_EVENT, payload: response });
  };
};

export const setOnNewYEvent = async (response: EventGestures): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_ON_NEW_Y_EVENT, payload: response });
  };
};

export const setOnLastXYEvent = async (response: EventGestures): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_ON_LAST_XY_EVENT, payload: response });
  };
};

export const setOnNewXYEvent = async (response: EventGestures): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_ON_NEW_XY_EVENT, payload: response });
  };
};

export const setIrishXPoint = async (response: number): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_IRISH_X_POINT, payload: response });
  };
};

export const setIrishYPoint = async (response: number): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_IRISH_Y_POINT, payload: response });
  };
};

export const setReferenceIrishYPoint = async (response: number): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_REFERENCE_IRISH_Y_POINT, payload: response });
  };
};

export const setRestoreReferenceIrishYPoint = () => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_RESTORE_REFERENCE_IRISH_Y_POINT });
  };
};

export const setOutOfRange = async (response: boolean): Promise<any> => {
  return async (dispatch: Dispatch): Promise<Action> => {
    return dispatch({ type: SET_OUT_OF_RANGE, payload: response });
  };
};

export const setTextOutput = (response: string) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    //Problema de dato
    return dispatch({ type: SET_TEXT_OUTPUT, payload: response });
  };
};

export const removeTextOutput = () => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: REMOVE_TEXT_OUTPUT });
  };
};

export const clearTextOutput = () => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: CLEAR_TEXT_OUTPUT });
  };
};

export const setServiceStatus = (response: boolean) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_SERVICE_STATUS, payload: response });
  };
};

export const setOnLoaderStatus = (response: boolean) => {
  return async (dispatch: Dispatch) => {
    return dispatch({ type: SET_ONLOADER_STATUS, payload: response });
  };
};
