import { COMMANDS, INITIALLETTERS } from '../../Utilities/Constans';
import { addTextOutPut, sliceTextOutPut, restoreLeftSection, restoreRightSection, clearTextOutPut, calculateMaxYPointEvent, addLetterToCommands } from '../../Utilities/Helpers';
import { Action, EventGestures, State } from '../../Models/Redux';
import {
  SET_LETTERS,
  RESTORE_LETTERS,
  SET_READY_TO_WRITE,
  SET_LEFT_LETTERS,
  SET_RIGHT_LETTERS,
  CLEAN_SECTION_LETTERS,
  SET_BYPASS,
  SET_COMMANDS,
  SET_LETTERS_TO_COMMANDS,
  RESTORE_COMMANDS,
  SET_LEFT_COMMANDS,
  SET_RIGHT_COMMANDS,
  SET_ON_LAST_X_EVENT,
  SET_ON_NEW_X_EVENT,
  SET_ON_LAST_Y_EVENT,
  SET_ON_NEW_Y_EVENT,
  SET_ON_LAST_XY_EVENT,
  SET_ON_NEW_XY_EVENT,
  SET_IRISH_X_POINT,
  SET_IRISH_Y_POINT,
  SET_REFERENCE_IRISH_Y_POINT,
  SET_RESTORE_REFERENCE_IRISH_Y_POINT,
  SET_TEXT_OUTPUT,
  SET_OUT_OF_RANGE,
  REMOVE_TEXT_OUTPUT,
  CLEAR_TEXT_OUTPUT,
  SET_SERVICE_STATUS,
  SET_ONLOADER_STATUS,
} from '../Actions';

const initialState: State = {
  letters: INITIALLETTERS,
  readyToWrite: false,
  leftSection: [],
  rightSection: [],
  bypass: false,
  commands: COMMANDS,
  commandsLeftSection: [],
  commandsRightSection: [],
  onLastXEvent: EventGestures.STRAIGHT,
  onNewXEvent: EventGestures.STRAIGHT,
  onLastYEvent: EventGestures.STRAIGHT,
  onNewYEvent: EventGestures.STRAIGHT,
  onLastXYEvent: EventGestures.NOTCENTERED,
  onNewXYEvent: EventGestures.NOTCENTERED,
  irishXPoint: 0,
  irishYPoint: 0,
  referenceIrishPoint: 0,
  isOutOfRange: true,
  textOutput: '',
  serviceStatus: false,
  onLoader: true,
};

const reducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_LETTERS:
      return { ...state, letters: action.payload };
    case RESTORE_LETTERS:
      return {
        ...state,
        letters: INITIALLETTERS,
        leftSection: restoreLeftSection(INITIALLETTERS),
        rightSection: restoreRightSection(INITIALLETTERS),
      };
    case SET_READY_TO_WRITE:
      return { ...state, readyToWrite: action.payload };
    case SET_LEFT_LETTERS:
      return { ...state, leftSection: action.payload };
    case SET_RIGHT_LETTERS:
      return { ...state, rightSection: action.payload };
    case CLEAN_SECTION_LETTERS:
      return { ...state, leftSection: [], rightSection: [] };
    case SET_COMMANDS:
      return { ...state, commands: action.payload };
    case SET_BYPASS:
      return { ...state, bypass: action.payload };
    case SET_LETTERS_TO_COMMANDS:
      return { ...state, commands: addLetterToCommands(state.commands, action.payload) };
    case RESTORE_COMMANDS:
      return {
        ...state,
        commands: COMMANDS,
        commandsLeftSection: restoreLeftSection(COMMANDS),
        commandsRightSection: restoreRightSection(COMMANDS),
      };
    case SET_LEFT_COMMANDS:
      return { ...state, commandsLeftSection: action.payload };
    case SET_RIGHT_COMMANDS:
      return { ...state, commandsRightSection: action.payload };
    case SET_ON_LAST_X_EVENT:
      return { ...state, onLastXEvent: action.payload };
    case SET_ON_NEW_X_EVENT:
      return { ...state, onNewXEvent: action.payload };
    case SET_ON_LAST_Y_EVENT:
      return { ...state, onLastYEvent: action.payload };
    case SET_ON_NEW_Y_EVENT:
      return { ...state, onNewYEvent: action.payload };
    case SET_ON_LAST_XY_EVENT:
      return { ...state, onLastXYEvent: action.payload };
    case SET_ON_NEW_XY_EVENT:
      return { ...state, onNewXYEvent: action.payload };
    case SET_IRISH_X_POINT:
      return { ...state, irishXPoint: action.payload };
    case SET_IRISH_Y_POINT:
      return { ...state, irishYPoint: action.payload };
    case SET_REFERENCE_IRISH_Y_POINT:
      return { ...state, referenceIrishPoint: calculateMaxYPointEvent(action.payload) };
    case SET_RESTORE_REFERENCE_IRISH_Y_POINT:
      return { ...state, referenceIrishPoint: 0 };
    case SET_OUT_OF_RANGE:
      return { ...state, isOutOfRange: action.payload };
    case SET_TEXT_OUTPUT:
      return { ...state, textOutput: state.textOutput + action.payload }; //Agregar funcion addTextOutPut , necesita el componente ts
    case REMOVE_TEXT_OUTPUT:
      return { ...state, textOutput: sliceTextOutPut(state.textOutput) };
    case CLEAR_TEXT_OUTPUT:
      return { ...state, textOutput: clearTextOutPut(state.textOutput) };
    case SET_SERVICE_STATUS:
      return { ...state, serviceStatus: action.payload };
    case SET_ONLOADER_STATUS:
      return { ...state, onLoader: action.payload };
    default:
      return state;
  }
};

export default reducer;
