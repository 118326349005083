import React from 'react';
import { ReactComponent as Logo } from '../../Assets/images/logo.svg';
import './LandscapeMessage.css';

const LandscapeMessage: React.FC = () => {
  return (
    <div className="landscape-message">
      <div className="logo-container">
        <Logo className="logo" />
      </div>
      <h1>Por favor, gire el dispositivo para usar la aplicación.</h1>
    </div>
  );
};

export default LandscapeMessage;
