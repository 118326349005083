import { ReactComponent as ArrowLeftIcon } from '../../Assets/icons/arrow-left-svgrepo-com.svg';
import { ReactComponent as ArrowUpIcon } from '../../Assets/icons/arrow-up-svgrepo-com.svg';
import { ReactComponent as ArrowRightIcon } from '../../Assets/icons/arrow-right-svgrepo-com.svg';
import { useDetectionStore } from '../../Models/useDetectionStore';
import ProgressBar from './ProgressBar';
import './EventBar.css';
import { KeyboardGestureEvent, useGestureKeyboardStore } from '../GestureKeyboard/useGestureKeyboardStore';
import { TDetectionConfig } from '../../Models/useDetectionStore';
const EventsBar = () => {
  const {
    rangeFiltering: { onRange },
    gestures: {
      active,
      scores: { lookLeftScore, lookRightScore, lookUpScore, lookDownScore },
    },
    config: {
      gestureScores: { activeThreshold, upScoreThreshold, idleThreshold },
    },
  } = useDetectionStore();

  const leftActivePin = lookLeftScore * 100 + '%';
  const leftActivePinEnabled = lookLeftScore > idleThreshold;

  const rightActivePin = lookRightScore * 100 + '%';
  const rightActivePinEnabled = lookRightScore > idleThreshold;
  const upActivePin = lookUpScore * 100 + '%';
  const upActivePinEnabled = lookUpScore > idleThreshold;

  const downActivePin = lookDownScore * 100 + '%';
  const activeThresholdPosition = 100 - activeThreshold * 100 + '%';
  const idleThresholdPosition = 100 - idleThreshold * 100 + '%';
  const verticalActiveThresholdPosition = upScoreThreshold * 100 + '%';
  const verticalIdleThresholdPosition = idleThreshold * 100 + '%';
  const rightActiveThreshold = activeThreshold * 100 + '%';
  const rightIdleThreshold = idleThreshold * 100 + '%';

  const leftActivePinProperties = {
    style: { right: leftActivePin },
    className: 'active-pin' + (lookRightScore < idleThreshold && lookLeftScore > idleThreshold ? (lookLeftScore > activeThreshold ? ' enabled' : '') : ' idle'),
  };

  const rightActivePinProperties = {
    style: { left: rightActivePin },
    className: 'active-pin' + (lookLeftScore < idleThreshold && lookRightScore > idleThreshold ? (lookRightScore > activeThreshold ? ' enabled' : '') : ' idle'),
  };

  const upActivePinProperties = {
    style: { bottom: upActivePin },
    className: 'active-pin' + (lookUpScore > idleThreshold ? (lookUpScore > upScoreThreshold ? ' enabled' : '') : ' idle'),
  };

  return (
    <div className="events-bar" style={{ opacity: onRange ? 1 : 0.3 }}>
      <div className="gauge left-event">
        <div className="active-threshold" style={{ left: activeThresholdPosition }}></div>
        <div className="idle-threshold" style={{ left: idleThresholdPosition }}></div>
        <div {...leftActivePinProperties}></div>
      </div>
      <div className="center-gauges">
        <div className="gauge up-event">
          <div className="active-threshold" style={{ bottom: verticalActiveThresholdPosition }}></div>
          <div className="idle-threshold" style={{ bottom: verticalIdleThresholdPosition }}></div>
          <div {...upActivePinProperties}></div>
        </div>
        {/* <div className="gauge down-event">
            <div className="active-threshold" style={{ bottom: verticalActiveThresholdPosition }}></div>
            <div className="active-pin" style={{ bottom: downActivePin }}></div>
          </div> */}
      </div>
      <div className="gauge right-event">
        <div className="active-threshold" style={{ left: rightActiveThreshold }}></div>
        <div className="idle-threshold" style={{ left: rightIdleThreshold }}></div>
        <div {...rightActivePinProperties}></div>
      </div>

      {/* <div
        className={'left-event arrow' + (active.lookLeftScore ? ' active' : ' idle')}
        onClick={() => setKeyboardGesture({ gesture: KeyboardGestureEvent.LOOK_LEFT, timeStamp: new Date().toISOString() })}>
        <ArrowLeftIcon className="arrow-icon" title="Flecha izquierda" />
        <ProgressBar normalizedScore={lookLeftScore} />
      </div>
      <div className={'up-event arrow' + (active.lookUpScore ? ' active' : ' idle')} onClick={() => setKeyboardGesture({ gesture: KeyboardGestureEvent.LOOK_UP, timeStamp: new Date().toISOString() })}>
        <ArrowUpIcon className="arrow-icon" title="Flecha arriba" />
        <ProgressBar normalizedScore={lookUpScore} />
      </div>
      <div
        className={'right-event arrow' + (active.lookRightScore ? ' active' : ' idle')}
        onClick={() => setKeyboardGesture({ gesture: KeyboardGestureEvent.LOOK_RIGHT, timeStamp: new Date().toISOString() })}>
        <ArrowRightIcon className="arrow-icon" title="Flecha derecha" />
        <ProgressBar normalizedScore={lookRightScore} />
      </div> */}
    </div>
  );
};

export default EventsBar;
