import React, { useRef, useEffect } from 'react';
import './Detector.css';
import Silhouette from '../Silhouette/Silhouette';
import useComputeScores from './hooks/useComputeScores';
import { useDetectionStore } from '../../Models/useDetectionStore';
import { DrawingUtils, FaceLandmarker, NormalizedLandmark } from '@mediapipe/tasks-vision';
import useRangeFiltering from './hooks/useRangeFiltering';
// import GestureCheck from './GestureCheck';
import useFaceLandMarker from '../../Services/useFaceLandMarker';
// import uPlot from 'uplot';
// import UPlotReact from 'uplot-react';
// const data: number[][] | Float64Array = [[], [], [], [], []];
// const convertToTypedArray = (data: number[][]): Float64Array[] => {
//   return data.map(innerArray => new Float64Array(innerArray));
// };

// const typedData = convertToTypedArray(data);

// const scales = {
//   x: {},
//   y: {
//     auto: false,
//     range: (): [number, number] => [0, 1], // Asegurarse de que devuelve un arreglo de dos elementos
//   },
// };

// const opts1: uPlot.Options = {
//   title: 'pxAlign: 1 (default)',
//   width: 100,
//   height: 150,
//   // ms: 1,

//   scales: scales,
//   series: [
//     {},
//     {
//       label: 'up',
//       stroke: 'red',
//       spanGaps: true,

//       // points: { show: true },
//     },
//     {
//       label: 'right',
//       stroke: 'blue',
//       spanGaps: true,
//     },
//     {
//       label: 'left',
//       stroke: 'purple',
//       spanGaps: true,
//     },
//   ],
// };

export const Detector: React.FC = () => {
  const {
    faceLandMark: { detector, result, drawingUtils, video },
    config: { videoSource },
    rangeFiltering: { onRange },
  } = useDetectionStore();

  const { webcamRef, canvasRef, videoRef } = useFaceLandMarker();
  const { normalized, max } = useComputeScores();
  const { boundingBox } = useRangeFiltering();
  // const [typedData, setTypedData] = useState<number[][] | Float64Array>(data);

  useEffect(() => {
    if (result?.faceLandmarks && drawingUtils && detector) {
      if (canvasRef.current && video) {
        canvasRef.current.width = video.videoWidth;
        canvasRef.current.height = video.videoHeight;
      }
      // console.log('[STATUS] drawDetection', result.faceLandmarks, drawingUtils, detector, onRange);
      drawDetection(result.faceLandmarks, drawingUtils, detector, onRange);
    }
  }, [result]);

  // useEffect(() => {
  //   if (gestures.last) {
  //     const newData = [...(typedData as number[][])];
  //     if (newData[0].length == 100) {
  //       newData[0].shift();
  //       newData[1].shift();
  //       newData[2].shift();
  //       newData[3].shift();
  //     }

  //     newData[0].push(Date.now());
  //     newData[1].push(normalized.lookUpScore);
  //     newData[2].push(normalized.lookRightScore);
  //     newData[3].push(normalized.lookLeftScore);

  //     setTypedData(newData);
  //     // console.table(gestures.scores);
  //   }
  // }, [gestures]);

  const drawDetection = (faceLandmarks: NormalizedLandmark[][], drawingUtils: DrawingUtils, faceLandmarker: FaceLandmarker, isOnRange: boolean) => {
    // console.log("entra a drawDetection",isOnRange)

    const boundingBoxProperties = {
      color: isOnRange ? 'rgba(0,254,20,.2)' : 'rgba(254,20,20,.3)',
      fillColor: 'transparent',
    };
    const baseColor = {
      color: isOnRange ? 'rgba(0,254,20,.2)' : 'rgba(254,20,20,.2)',
      lineWidth: 1,
    };

    const highLightColor = {
      color: isOnRange ? 'rgba(0,184,20,.4)' : 'rgba(184,20,20,.5)',
      lineWidth: 2,
    };

    const solidColor = {
      color: isOnRange ? 'rgba(0,254,20,.9)' : 'rgba(254,20,20,.9)',
      lineWidth: 2,
    };

    if (faceLandmarks && drawingUtils) {
      for (const landmarks of faceLandmarks) {
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_TESSELATION, baseColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW, baseColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYE, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW, baseColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_FACE_OVAL, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LIPS, highLightColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS, solidColor);
        // drawBoundingBox(landmarks)
      }
    }

    // console.log(boundingBox)
    // if (boundingBox !== undefined) {
    //   drawingUtils.drawBoundingBox(boundingBox, boundingBoxProperties);
    // }
  };

  const updateCountRef = useRef(0);
  const fpsRef = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      fpsRef.current = updateCountRef.current;
      updateCountRef.current = 0;
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    updateCountRef.current++;
  });

  // console.log('[STATUS] onRange', onRange, scores, active, idle);
  return (
    <div className="detectorContainer">
      {/* <h3 className="legend">Mire hacia la izquierda o hacia la derecha para filtrar la letra/comando que desea seleccionar.</h3> */}
      <div className="centerColumn">
        <div className="camContainer">
          {videoSource.active ? (
            <video
              id="video"
              ref={videoRef}
              src={'prueba.mp4'}
              className="cameraFeed"
              autoPlay
              loop
              muted
              style={{
                // position: 'absolute',
                // left: 0,
                // right: 0,
                textAlign: 'center',
                zIndex: 9,
                width: '100%',
                height: 'auto',
                transform: 'scaleX(-1)',
                borderRadius: '10px',
                flex: 1,
              }}
            />
          ) : (
            <video
              id="webcam"
              ref={webcamRef}
              autoPlay
              className="cameraFeed"
              style={{
                // position: 'absolute',
                left: 0,
                right: 0,
                textAlign: 'center',
                zIndex: 9,
                width: '100%',
                height: 'auto',
                transform: 'scaleX(-1)',
                borderRadius: '10px',
                flex: 1,
              }}
            />
          )}

          <canvas
            ref={canvasRef}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              textAlign: 'center',
              zIndex: 9,
              width: '100%',
              transform: 'scaleX(-1)',
              height: 'auto',
            }}
          />
          {/* <button id="webcamButton" className="mdc-button mdc-button--raised">
            <span className="mdc-button__ripple"></span>
            <span className="mdc-button__label">ENABLE WEBCAM</span>
          </button> */}
          <Silhouette />
        </div>
        {/* <h3 style={{ color: onRange ? 'rgba(100,255,100,1)' : 'rgba(255,255,255,.4)', textTransform: 'capitalize' }}>
          {' '}
          {onRange ? '[ EN RANGO ]' : '[ EN RANGO ]'}
        </h3> */}
        {/* <h3>FPS: {fpsRef.current}</h3> */}
        <div style={{ background: 'white' }}>{/* <GestureCheck scores={normalized} max={max} /> */}</div>
      </div>
      {/* <h3 className="legend">
        <UPlotReact options={opts1} data={convertToTypedArray(typedData as number[][])} />
        Mire hacia arriba para reproducir el texto
      </h3> */}
    </div>
  );
};
