import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../../Services/AuthService';
import IUser from '../../Models/Types';
import './Login.css';
import logo from '../../Assets/images/logo.svg';
import LandscapeMessage from '../../Xomponents/LandscapeMessage';

type Props = {
  setCurrentUser: (user: IUser) => void;
};

const Login: React.FC<Props> = ({ setCurrentUser }) => {
  const navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const initialValues = {
    email: '',
    password: '',
  };


  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);



  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('This field is required!').email('Invalid email address'),
    password: Yup.string().required('This field is required!'),
  });

  const handleLogin = (formValue: { email: string; password: string }) => {
    const { email, password } = formValue;

    setMessage('');
    setLoading(true);

    login(email, password).then(
      data => {
        setCurrentUser(data);
        navigate('/home');
      },
      error => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  return (
    <div className="loginContainer">

      {!isLandscape && <LandscapeMessage />}

      <div className='logo_login_wrapper'>
        <img alt="logo" src={logo} className="logo_login"></img>
      </div>

      <div className="form-container">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
          <Form>
            <div className="form-group">
              <label htmlFor="username">Email</label>
              <Field name="email" type="text" className="form-control" />
              <ErrorMessage name="email" component="div" className="alert alert-danger" />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage name="password" component="div" className="alert alert-danger" />
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm"></span>}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>


    </div>
  );
};

export default Login;
