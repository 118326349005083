import { create } from 'zustand';

/**
 * Enum de comandos disponibles.
 */
enum COMMANDS {
  DELETE = 'BORRAR',
  DELETE_ALL = 'BORRAR TODO',
  SPACE = 'ESPACIO',
  SPEAK = 'PRONUNCIAR',
  RESET = 'REINICIAR',
}

/**
 * Lista de teclas disponibles.
 */
export const KEY_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

/**
 * Lista de comandos disponibles.
 */

const LEFT_COMMANDS = [COMMANDS.DELETE, COMMANDS.DELETE_ALL];
const RIGHT_COMMANDS = [COMMANDS.SPACE, COMMANDS.SPEAK, COMMANDS.RESET];

/**
 * Enum de eventos de gestos del teclado.
 */
export enum KeyboardGestureEvent {
  LOOK_LEFT = 'look_left',
  LOOK_RIGHT = 'look_right',
  LOOK_UP = 'look_up',
  LOOK_DOWN = 'look_down',
  LOOK_IDLE = 'look_idle',
}

/**
 * Interfaz que representa un registro de gesto.
 */
interface IGestureRecord {
  gesture: KeyboardGestureEvent;
  timeStamp: string;
}

/**
 * Registro de gesto vacío.
 */
const emptyGestureRecord: IGestureRecord = {
  gesture: KeyboardGestureEvent.LOOK_IDLE,
  timeStamp: '',
};

/**
 * Interfaz que define la estructura del estado del store del teclado de gestos.
 */
interface IKeyboardGestureStore {
  config: {
    validatationTime: number;
    initialKeys: string[];
  };
  oldGesture: IGestureRecord | undefined;
  currentGesture: IGestureRecord | undefined;
  frase: string;
  leftSide: string[];
  rightSide: string[];

  left: {
    keys: string[];
    commands: string[];
  };
  right: {
    keys: string[];
    commands: string[];
  };

  setKeyboardGesture: (keyboardGesture: IGestureRecord | undefined) => void;
  init: () => void;
  pick: (letters: string[], commands: string[]) => void;
}

/**
 * Determina las teclas y comandos de la mitad izquierda.
 * @param keys - Lista de teclas.
 * @param commands - Lista de comandos.
 * @returns Un objeto con las teclas y comandos de la mitad izquierda.
 */
const resolveLeft = (keys: string[], commands: string[]) => {
  const out: {
    keys: string[];
    commands: string[];
  } = {
    keys: [],
    commands: [],
  };

  // si hay una tecla y no hay comandos
  if (keys.length === 1 && commands.length === 0) {
    out.keys = KEY_LIST.slice(0, KEY_LIST.length / 2);
    out.commands = [...LEFT_COMMANDS];
    // si hay una tecla y un comando
  } else if (keys.length === 1 && commands.length === 1) {
    out.keys = keys;
    out.commands = [];
    // si hay una tecla y mas de un comando
  } else if (keys.length === 1 && commands.length > 1) {
    out.keys = keys;
    out.commands = [];
    // si no hay teclas y un comando
  } else if (keys.length == 0 && commands.length == 1) {
    out.keys = KEY_LIST.slice(0, KEY_LIST.length / 2);
    out.commands = [...LEFT_COMMANDS];
    // si no hay teclas y mas de un comando
  } else if (keys.length == 0 && commands.length > 1) {
    out.keys = [];
    out.commands = commands.slice(0, commands.length / 2);
    // si hay mas de una tecla y no hay comandos
  } else {
    out.keys = keys.slice(0, keys.length / 2);
    out.commands = [...LEFT_COMMANDS];
  }

  return out;
};

/**
 * Determina las teclas y comandos de la mitad derecha.
 * @param keys - Lista de teclas.
 * @param commands - Lista de comandos.
 * @returns Un objeto con las teclas y comandos de la mitad derecha.
 */
const resolveRight = (keys: string[], commands: string[]) => {
  const out: {
    keys: string[];
    commands: string[];
  } = {
    keys: [],
    commands: [],
  };

  // si hay una tecla y no hay comandos
  if (keys.length === 1 && commands.length === 0) {
    out.keys = KEY_LIST.slice(KEY_LIST.length / 2);
    out.commands = [...RIGHT_COMMANDS];
    // si hay una tecla y un comando
  } else if (keys.length === 1 && commands.length === 1) {
    out.keys = [];
    out.commands = commands;
    // si hay una tecla y mas de un comando
  } else if (keys.length === 1 && commands.length > 1) {
    out.keys = [];
    out.commands = commands;
    // si no hay teclas y un comando
  } else if (keys.length == 0 && commands.length == 1) {
    out.keys = KEY_LIST.slice(KEY_LIST.length / 2);
    out.commands = [...RIGHT_COMMANDS];
    // si no hay teclas y mas de un comando
  } else if (keys.length == 0 && commands.length > 1) {
    out.keys = [];
    out.commands = commands.slice(commands.length / 2);
    // si hay mas de una tecla y no hay comandos
  } else {
    out.keys = keys.slice(keys.length / 2);
    out.commands = [...RIGHT_COMMANDS];
  }

  return out;
};

/**
 * Resuelve el último carácter basado en los comandos de control.
 * @param frase - Frase actual.
 * @param letters - Letras o comandos.
 * @returns La frase actualizada.
 */
const resolveLastCharacter = (frase: string, keys: string[], commands: string[]) => {
  if (keys.length === 0 && commands.length === 1) {
    if (commands[0] === COMMANDS.DELETE) return frase.slice(0, frase.length - 1);
    if (commands[0] === COMMANDS.DELETE_ALL) return '';
    if (commands[0] === COMMANDS.SPACE) return frase + ' ';
    if (commands[0] === COMMANDS.SPEAK) return frase;
    if (commands[0] === COMMANDS.RESET) return frase;
  } else if (keys.length === 1 && commands.length === 0) {
    return frase + keys[0];
  }

  return frase;
};

/**
 * Devuelve un gesto de hablar si el comando correspondiente es detectado.
 * @param letters - Letras o comandos.
 * @returns Un gesto de hablar o undefined.
 */
const resolveTalkGesture = (keys: string[], commands: string[]) => {
  if (keys.length === 0 && commands.length === 1 && commands[0] === COMMANDS.SPEAK) {
    return { gesture: KeyboardGestureEvent.LOOK_UP, timeStamp: new Date().toISOString() };
  }
  return undefined;
};

/**
 * Store de Zustand que mantiene el estado del teclado de gestos.
 */
export const useGestureKeyboardStore = create<IKeyboardGestureStore>((set, get) => ({
  config: {
    validatationTime: 1000,
    initialKeys: [...KEY_LIST],
  },
  oldGesture: undefined,
  currentGesture: undefined,
  frase: '',
  leftSide: [],
  rightSide: [],
  left: {
    keys: [],
    commands: [],
  },
  right: {
    keys: [],
    commands: [],
  },
  setKeyboardGesture: keyboardGesture =>
    set(state => ({
      ...state,
      oldGesture: state.currentGesture || keyboardGesture,
      currentGesture: keyboardGesture,
    })),

  resetKeyboardKeys: () =>
    set(state => ({
      frase: '',

      left: {
        keys: state.config.initialKeys.slice(0, state.config.initialKeys.length / 2),
        commands: [COMMANDS.DELETE, COMMANDS.DELETE_ALL],
      },
      right: {
        keys: state.config.initialKeys.slice(state.config.initialKeys.length / 2),
        commands: [COMMANDS.SPACE, COMMANDS.SPEAK],
      },
      currentGesture: undefined,
    })),

  init: () =>
    set(state => ({
      frase: '',

      left: {
        keys: state.config.initialKeys.slice(0, state.config.initialKeys.length / 2),
        commands: [...LEFT_COMMANDS],
      },
      right: {
        keys: state.config.initialKeys.slice(state.config.initialKeys.length / 2),
        commands: [...RIGHT_COMMANDS],
      },

      currentGesture: undefined,
    })),
  pick: (keys: string[], commands: string[]) => {
    // console.log('[STATUS] useGestureKeyboardStore:', keys);
    // console.log('[STATUS] letrasNoComandos:', letrasNoComandos);

    set(state => {
      // console.log('[STATUS] newState:', newState);
      return {
        frase: resolveLastCharacter(state.frase, keys, commands),
        left: resolveLeft(keys, commands),
        right: resolveRight(keys, commands),
        currentGesture: resolveTalkGesture(keys, commands),
      };
    });
  },
}));
