import React, { useEffect, useState } from 'react';
import OptionsMenu from '../Xomponents/OptionsMenu';

import './Layout.css';
import LandscapeMessage from '../Xomponents/LandscapeMessage';
interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const checkOrientation = () => {
      const isLandscapeNow = window.innerWidth > window.innerHeight;
      console.log('Is Landscape: ', isLandscapeNow);
      setIsLandscape(isLandscapeNow);
    };
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  if (!isLandscape) {
    return <LandscapeMessage />;
  }

  return (
    <div className="layout">
      <div className="grid-container">
        <div className="top-left-corner"></div>
        <div className="top-center-bar"></div>
        <div className="top-right-corner">
          <OptionsMenu />
        </div>
        <div className="middle-left-bar"></div>
        <div className="content">{children}</div>
        <div className="middle-right-bar"></div>
        <div className="bottom-left-corner"></div>
        <div className="bottom-center-bar"></div>
        <div className="bottom-right-corner"></div>
      </div>
    </div>
  );
};

export default Layout;
