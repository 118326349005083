import { useEffect, useRef, useState } from 'react';
import { EyeGestures, Gestures, TGesstureScores, TGestureScoresFlag, emptyGestureScores, emptyGestureScoresFlag, useDetectionStore } from '../../../Models/useDetectionStore';
import { Category } from '@mediapipe/tasks-vision';

type TScoreTrackingState = {
  historic: TGesstureScores[];
  max: TGesstureScores;
  normalized: TGesstureScores;
  last: TGesstureScores;
  idle: TGestureScoresFlag;
  active: TGestureScoresFlag;
};

const EmptyScoreTrackingState: TScoreTrackingState = {
  historic: [],
  max: {
    lookUpScore: 0.3,
    lookDownScore: 0.5,
    lookLeftScore: 0.5,
    lookRightScore: 0.5,
    lookIdleScore: 0,
    timestamp: '',
  },
  normalized: {
    lookUpScore: 0,
    lookDownScore: 0,
    lookLeftScore: 0,
    lookRightScore: 0,
    lookIdleScore: 0,
    timestamp: '',
  },
  last: {
    lookUpScore: 0,
    lookDownScore: 0,
    lookLeftScore: 0,
    lookRightScore: 0,
    lookIdleScore: 0,
    timestamp: '',
  },
  idle: {
    lookUpScore: false,
    lookDownScore: false,
    lookLeftScore: false,
    lookRightScore: false,
    lookIdleScore: false,
  },
  active: {
    lookUpScore: false,
    lookDownScore: false,
    lookLeftScore: false,
    lookRightScore: false,
    lookIdleScore: false,
  },
};

const getNewScores = (categories: Category[]) => {
  const gestures: { [key: string]: number } = {};
  categories.map((shape: Category) => {
    const { displayName, categoryName, score } = shape;
    const id = (displayName || categoryName) as EyeGestures;
    if (Object.values(EyeGestures).includes(id)) {
      gestures[id] = score;
    }
  });
  const { eyeLookUpLeft, eyeLookUpRight, eyeLookDownLeft, eyeLookDownRight, eyeLookInRight, eyeLookOutLeft, eyeLookInLeft, eyeLookOutRight } = gestures;

  const record: TGesstureScores = {
    lookUpScore: Math.max(eyeLookUpLeft, eyeLookUpRight),
    lookDownScore: Math.max(eyeLookDownLeft, eyeLookDownRight),
    lookLeftScore: eyeLookOutLeft,
    // lookLeftScore: Math.max(eyeLookInRight, eyeLookOutLeft),
    lookRightScore: eyeLookOutRight,
    // lookRightScore: Math.max(eyeLookInLeft, eyeLookOutRight),
    lookIdleScore: 0,
    timestamp: new Date().toISOString(),
  };

  return record;
};

const useComputeScores = () => {
  const {
    rangeFiltering: { onRange },
    config: {
      gestureScores: { idleThreshold, activeThreshold, verticalActiveThreshold, historyDepth, upScoreThreshold, downScoreThreshold },
    },
    faceLandMark: { result },
    setGestureScores,
  } = useDetectionStore();

  const [scoresState, setScoresState] = useState<TScoreTrackingState>({ ...EmptyScoreTrackingState });

  const updateScores = (categories: Category[]) => {
    const newScores = getNewScores(categories);
    if (JSON.stringify(newScores) === JSON.stringify(scoresState.last)) {
      return;
    }

    const { lookUpScore, lookDownScore, lookLeftScore, lookRightScore, lookIdleScore, timestamp } = newScores;
    const newState = { ...scoresState, last: { ...newScores } };
    const { max, normalized, historic, idle, active } = newState;

    // Coeficiente de degradación
    const degradationCoefficient = 1;

    // Actualiza los máximos y aplica degradación si no se actualizan
    max.lookUpScore = lookUpScore > max.lookUpScore ? lookUpScore : max.lookUpScore * degradationCoefficient;
    max.lookDownScore = lookDownScore > max.lookDownScore ? lookDownScore : max.lookDownScore * degradationCoefficient;
    max.lookLeftScore = lookLeftScore > max.lookLeftScore ? lookLeftScore : max.lookLeftScore * degradationCoefficient;
    max.lookRightScore = lookRightScore > max.lookRightScore ? lookRightScore : max.lookRightScore * degradationCoefficient;
    max.lookIdleScore = lookIdleScore > max.lookIdleScore ? lookIdleScore : max.lookIdleScore * degradationCoefficient;

    historic.push(newScores);
    if (historic.length > historyDepth) {
      historic.shift();
    }

    const averageScores = {
      lookUpScore: 0,
      lookDownScore: 0,
      lookLeftScore: 0,
      lookRightScore: 0,
      lookIdleScore: 0,
    };

    if (historic.length > 0) {
      const totalScores = historic.reduce(
        (acc, scores) => {
          acc.lookUpScore += scores.lookUpScore;
          acc.lookDownScore += scores.lookDownScore;
          acc.lookLeftScore += scores.lookLeftScore;
          acc.lookRightScore += scores.lookRightScore;
          acc.lookIdleScore += scores.lookIdleScore;
          return acc;
        },
        { ...averageScores }
      );

      const historicCount = historic.length;
      averageScores.lookUpScore = totalScores.lookUpScore / historicCount;
      averageScores.lookDownScore = totalScores.lookDownScore / historicCount;
      averageScores.lookLeftScore = totalScores.lookLeftScore / historicCount;
      averageScores.lookRightScore = totalScores.lookRightScore / historicCount;
      averageScores.lookIdleScore = totalScores.lookIdleScore / historicCount;
    }

    normalized.lookUpScore = averageScores.lookUpScore / max.lookUpScore;
    normalized.lookDownScore = averageScores.lookDownScore / max.lookDownScore;
    normalized.lookLeftScore = averageScores.lookLeftScore / max.lookLeftScore;
    normalized.lookRightScore = averageScores.lookRightScore / max.lookRightScore;
    normalized.lookIdleScore = 1 - Math.max(normalized.lookUpScore, normalized.lookDownScore, normalized.lookLeftScore, normalized.lookRightScore);

    normalized.timestamp = timestamp;
    idle.lookUpScore = normalized.lookUpScore < idleThreshold;
    idle.lookDownScore = normalized.lookDownScore < idleThreshold;
    idle.lookLeftScore = normalized.lookLeftScore < idleThreshold;
    idle.lookRightScore = normalized.lookRightScore < idleThreshold;
    idle.lookIdleScore = idle.lookDownScore && idle.lookUpScore && idle.lookLeftScore && idle.lookRightScore;

    active.lookUpScore = normalized.lookUpScore > upScoreThreshold;
    active.lookDownScore = normalized.lookDownScore > downScoreThreshold;
    active.lookLeftScore = normalized.lookLeftScore > activeThreshold;
    active.lookRightScore = normalized.lookRightScore > activeThreshold;
    active.lookIdleScore = !(active.lookDownScore || active.lookUpScore || active.lookLeftScore || active.lookRightScore);
    setScoresState(newState);
    setGestureScores(normalized, newScores, idle, active);
    // console.table([
    //   { in: newScores.lookUpScore, norm: normalized.lookUpScore, vertAct: verticalActiveThreshold, max: max.lookUpScore, actv: active.lookUpScore },
    // ]);
    // console.log('[STATUS] useComputeScores scoresState');
  };

  useEffect(() => {
    // console.log('[STATUS] useComputeScores onRange', onRange);
    if (!onRange && scoresState.max.lookUpScore !== 0) {
      setScoresState({
        ...scoresState,
        max: {
          lookUpScore: scoresState.max.lookUpScore < 0.7 ? scoresState.max.lookUpScore : 0.7,
          lookDownScore: scoresState.max.lookDownScore < 0.7 ? scoresState.max.lookDownScore : 0.7,
          lookLeftScore: scoresState.max.lookLeftScore < 0.7 ? scoresState.max.lookLeftScore : 0.7,
          lookRightScore: scoresState.max.lookRightScore < 0.7 ? scoresState.max.lookRightScore : 0.7,
          lookIdleScore: 0,
          timestamp: new Date().toISOString(),
        },
      });
      //   console.log('[STATUS] useComputeScores maxValues reseted');
    }
  }, [onRange]);

  const updateInterval = 1000 / 25; // 30 updates per second
  const lastUpdateTimeRef = useRef(Date.now());

  useEffect(() => {
    if (result?.faceBlendshapes[0]?.categories) {
      //   console.log('[STATUS] useComputeScores scoresState', result?.faceBlendshapes[0].categories);
      // updateScores(result.faceBlendshapes[0].categories);

      if (Date.now() - lastUpdateTimeRef.current >= updateInterval) {
        updateScores(result.faceBlendshapes[0].categories);
        lastUpdateTimeRef.current = Date.now();
      }
    }
  }, [result?.faceBlendshapes[0]]);

  return {
    max: scoresState.max,
    normalized: scoresState.normalized,
    idle: scoresState.idle,
    active: scoresState.active,
  };
};

export default useComputeScores;
