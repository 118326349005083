import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Presentation from '../../Xomponents/Presentation/Presentation';
import Loader from '../../Xomponents/Loader/Loader';
import { logout } from '../../Services/AuthService';
import './Home.css';
import IUser from '../../Models/Types';
import TextToSpeech from '../../Xomponents/TextToSpeech/TextToSpeech';
import { useDetectionStore } from '../../Models/useDetectionStore';
import GestureKeyboard from '../../Xomponents/GestureKeyboard';
import FullScreenButton from './FullScreenButton';
import GestureFeedBack from '../../Xomponents/GestureFeedback/GestureFeedback';
import LandscapeMessage from '../../Xomponents/LandscapeMessage';

interface HomeProps {
  setCurrentUser: (user: IUser | null) => void;
}

const Home: React.FC<HomeProps> = ({ setCurrentUser }) => {
  const {
    faceLandMark: { complete },
    config: {
      videoSource: { active },
    },
    setVideoSourceActive,
  } = useDetectionStore();
  const navigate: NavigateFunction = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
    const checkOrientation = () => {
      const isLandscapeNow = window.innerWidth > window.innerHeight;
      console.log('Is Landscape: ', isLandscapeNow);
      setIsLandscape(isLandscapeNow);
    };
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, [complete]);

  const handlePlayButtonClick = () => {
    if (!isPlaying) setIsPlaying(true);
  };

  const handlePlaybackEnd = () => {
    setIsPlaying(false);
  };

  const handleLogout = () => {
    logout();
    setCurrentUser(null);
    navigate('/login');
  };

  // const handleVideoSource = () => {
  //   setVideoSourceActive(!active);
  // };
  return (
    <div className="home-container" style={{ position: 'relative' }}>
      <GestureFeedBack />
      {!complete && <Loader />}
      {!isLandscape && <LandscapeMessage />}

      <div id="homeMain" style={{ display: isLandscape ? 'flex' : 'none' }}>
        <div className="home-buttons">
          <button onClick={handlePlayButtonClick} className="btn btn-secondary btn-block  play-button">
            Reproducir
          </button>
          <FullScreenButton />
          <button onClick={handleLogout} className="btn btn-primary btn-block logout-button">
            Logout
          </button>
        </div>
        {/* <button onClick={handleVideoSource} className="btn btn-primary btn-block logout-button">
          video {active ? 'on' : 'off'}
        </button> */}
        <GestureKeyboard />
        {/* <Detector /> */}
        <TextToSpeech onEnd={handlePlaybackEnd} />
      </div>
    </div>
  );
};

export default Home;
