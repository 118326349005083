import React, { useEffect, useState } from 'react';
import * as azureSdk from 'microsoft-cognitiveservices-speech-sdk';
import { KeyboardGestureEvent, useGestureKeyboardStore } from '../GestureKeyboard/useGestureKeyboardStore';

const azureSpeechConfig = azureSdk.SpeechConfig.fromSubscription(process.env.AZURE_SPEECH_KEY || 'e7cc1f5b0b7d4dfc82693c015350d5e7', process.env.AZURE_SPEECH_SERVICE_REGION || 'eastus');

azureSpeechConfig.speechSynthesisVoiceName = 'es-AR-TomasNeural';

interface TextToSpeechProps {
  onEnd: () => void;
}

const TextToSpeech: React.FC<TextToSpeechProps> = ({ onEnd }) => {
  const { frase, currentGesture, setKeyboardGesture } = useGestureKeyboardStore();
  const [isSynthesizing, setIsSynthesizing] = useState(false);

  useEffect(() => {
    if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_UP && frase !== undefined) {
      handlerSynthesizing(frase, isSynthesizing);
      setKeyboardGesture(undefined);
    }
    //debug para saber si se esta ejecutando
    // if (currentGesture === KeyboardGestureEvent.LOOK_DOWN) {
    //   handlerSynthesizing('abajo', isSynthesizing);
    //   setKeyboardGesture(undefined);
    // }
    //debug para saber si se esta ejecutando
    // if (currentGesture === KeyboardGestureEvent.LOOK_LEFT) {
    //   handlerSynthesizing('izquierda', isSynthesizing);
    //   setKeyboardGesture(undefined);
    // }

    // if (currentGesture === KeyboardGestureEvent.LOOK_RIGHT) {
    //   handlerSynthesizing('derecha', isSynthesizing);
    //   setKeyboardGesture(undefined);
    // }

    // if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_IDLE) {
    //   handlerSynthesizing('centrado', isSynthesizing);
    //   setKeyboardGesture(undefined);
    // }
  }, [currentGesture, onEnd]);

  const handlerSynthesizing = (textOutput: string, isSynthesizing: boolean): void => {
    if (textOutput && !isSynthesizing) {
      setIsSynthesizing(true);
      const synthesizer = new azureSdk.SpeechSynthesizer(azureSpeechConfig);

      synthesizer.speakTextAsync(
        textOutput,
        result => {
          if (result.reason === azureSdk.ResultReason.SynthesizingAudioCompleted) {
            console.log('Síntesis completada.');
          } else {
            console.error('Error en la síntesis de voz.');
          }
          synthesizer.close();
          setIsSynthesizing(false);
          onEnd();
        },
        error => {
          console.error('Error de síntesis:', error);
          synthesizer.close();
          setIsSynthesizing(false);
          onEnd();
        }
      );
    }
  };

  return <div className="textToSpeechContainer"> {isSynthesizing ? '...' : frase}</div>;
};

export default TextToSpeech;
