import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Home from './Sections/Home/Home';
import { useEffect, useState } from 'react';
import IUser from './Models/Types';
import { getCurrentUser } from './Services/AuthService';
import Login from './Sections/Login/Login';
import NotFoundPage from './Xomponents/NotFound/NotFoundPage';
import Tutorial from './Sections/Tutorial/Tutorial';
import Layout from './Layout/Layout';

function App() {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const user = getCurrentUser();
    setCurrentUser(user);
    setLoading(false);
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/layout" element={<Layout> asdasd</Layout>} />
        <Route path="/login" element={!currentUser ? <Login setCurrentUser={setCurrentUser} /> : <Navigate replace to="/home" />} />
        <Route path="/tutorial" element={currentUser ? <Tutorial setCurrentUser={setCurrentUser} /> : <Navigate replace to="/login" />} />
        <Route path="/home" element={currentUser ? <Home setCurrentUser={setCurrentUser} /> : <Navigate replace to="/login" />} />
        <Route path="/" element={<Navigate replace to={currentUser ? '/home' : '/login'} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
