import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../Models/Redux';
import userOutlineGreen from '../../Assets/images/user-outline-green.png';
import userOutlineRed from '../../Assets/images/user-outline-red.png';
import './Silhouette.css';
import { useDetectionStore } from '../../Models/useDetectionStore';

const Silhouette: React.FC = () => {
  const isOutOfRange: boolean = useSelector((state: State) => state.isOutOfRange);

  const {
    rangeFiltering: { onRange },
    config: { videoSource },
    faceLandMark: { video },
  } = useDetectionStore();
  useEffect(() => {
    //
  }, [isOutOfRange, videoSource]);

  return (
    <img
      style={{
        width: video?.offsetWidth ? video?.offsetWidth + 'px' : '100%',
        height: video?.offsetHeight ? video?.offsetHeight + 'px' : 'auto',
      }}
      src={onRange ? userOutlineGreen : userOutlineRed}
      className="userSilhouette"
    />
  );
};

export default Silhouette;
