export type Action = {
  type: string;
  payload: any;
};

export type State = {
  letters: string[];
  readyToWrite: boolean;
  leftSection: string[];
  rightSection: string[];
  bypass: boolean;
  commands: string[];
  commandsLeftSection: string[];
  commandsRightSection: string[];
  onLastXEvent: EventGestures;
  onNewXEvent: EventGestures;
  onLastYEvent: EventGestures;
  onNewYEvent: EventGestures;
  onLastXYEvent: EventGestures;
  onNewXYEvent: EventGestures;
  irishXPoint: number;
  irishYPoint: number;
  referenceIrishPoint: number;
  isOutOfRange: boolean;
  textOutput: string;
  serviceStatus: boolean;
  onLoader: boolean;
};

export enum EventGestures {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  STRAIGHT = 'STRAIGHT',
  TOP = 'TOP',
  CENTERED = 'CENTERED',
  NOTCENTERED = 'NOTCENTERED',
  OUTOFRANGE = 'OUTOFRANGE',
}
