import React, { useState, useEffect } from 'react';

const FullScreenButton: React.FC = () => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(prev => !prev);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(e => console.error(e));
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch(e => console.error(e));
      }
    }
  };

  return (
    <button onClick={toggleFullScreen} className="btn btn-secondary btn-block  fullScreenButton">
      {isFullScreen ? 'Salir' : 'Pantalla completa'}
    </button>
  );
};

export default FullScreenButton;
