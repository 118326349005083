import axios from 'axios';
import IUser from '../Models/Types';

const API_URL = `https://${process.env.REACT_APP_API}/user/login`;

export const login = (email: string, password: string) => {
  return axios
    .post(API_URL, {
      email,
      password,
    })
    .then(response => {
      if (response.data.authToken) {
        localStorage.setItem('user', JSON.stringify(response.data.authToken));
      }

      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem('user');
};

export const getCurrentUser = (): IUser | null => {
  const userToken = localStorage.getItem('user');

  if (userToken) return { authToken: userToken };

  return null;
};
