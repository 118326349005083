import React, { useEffect } from 'react';
import './EventCreator.css';
import { TGestureScoresFlag, useDetectionStore } from '../../Models/useDetectionStore';
import { KeyboardGestureEvent, useGestureKeyboardStore } from '../GestureKeyboard/useGestureKeyboardStore';

const EventCreator: React.FC = () => {
  const {
    rangeFiltering: { onRange },
    gestures,
  } = useDetectionStore();

  const { setKeyboardGesture, currentGesture, oldGesture } = useGestureKeyboardStore();

  const triggerHappy = async (active: TGestureScoresFlag, inactive: TGestureScoresFlag, timeStamp: string) => {
    let candidateGesture: KeyboardGestureEvent | undefined = undefined;

    if (inactive.lookUpScore && inactive.lookLeftScore && inactive.lookRightScore) {
      //console.log('[STATUS] EventCreator:', 'No se detecta movimiento', oldGesture);
      //console.table({ lookUpScore: inactive.lookUpScore, lookLeftScore: inactive.lookLeftScore, lookRightScore: inactive.lookRightScore });
      candidateGesture = KeyboardGestureEvent.LOOK_IDLE;
    } else {
      if (active.lookUpScore) {
        // console.log('[STATUS] EventCreator:', lookUpScore);
        candidateGesture = KeyboardGestureEvent.LOOK_UP;
      } else if (active.lookLeftScore) {
        candidateGesture = KeyboardGestureEvent.LOOK_LEFT;
      } else if (active.lookRightScore) {
        candidateGesture = KeyboardGestureEvent.LOOK_RIGHT;
      } else if (inactive.lookDownScore) {
        candidateGesture = KeyboardGestureEvent.LOOK_DOWN;
      }
    }

    if (oldGesture !== candidateGesture && candidateGesture !== undefined) {
      const currentTime = new Date(timeStamp).getTime();
      const oldTime = oldGesture?.timeStamp ? new Date(oldGesture.timeStamp).getTime() : null;
      if (oldTime === null || currentTime - oldTime >= 1000) {
        console.log('[STATUS] EventCreator:', oldGesture, candidateGesture);
        setKeyboardGesture({ gesture: candidateGesture, timeStamp });
      }
    }
  };

  useEffect(() => {
    // console.log('[STATUS] EventCreator:', onRange);
    if (onRange && !gestures.active.lookIdleScore) {
      triggerHappy(gestures.active, gestures.idle, gestures.scores.timestamp);
    }
  }, [gestures, onRange]);

  return <div className="eventCreator"></div>;
};

export default EventCreator;
