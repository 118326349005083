import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Presentation from '../../Xomponents/Presentation/Presentation';
import Loader from '../../Xomponents/Loader/Loader';
import { logout } from '../../Services/AuthService';
import './Tutorial.css';
import IUser from '../../Models/Types';
import TextToSpeech from '../../Xomponents/TextToSpeech/TextToSpeech';
import { useDetectionStore } from '../../Models/useDetectionStore';
import GestureKeyboard from '../../Xomponents/GestureKeyboard';
import GestureFeedBack from '../../Xomponents/GestureFeedback/GestureFeedback';

interface TutorialProps {
  setCurrentUser: (user: IUser | null) => void;
}

const Home: React.FC<TutorialProps> = ({ setCurrentUser }) => {
  const {
    faceLandMark: { complete },
    config: {
      videoSource: { active },
    },
    setVideoSourceActive,
  } = useDetectionStore();
  const navigate: NavigateFunction = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
    const checkOrientation = () => {
      const isLandscapeNow = window.innerWidth > window.innerHeight;
      console.log('Is Landscape: ', isLandscapeNow);
      setIsLandscape(isLandscapeNow);
    };
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, [complete]);

  const handlePlayButtonClick = () => {
    if (!isPlaying) setIsPlaying(true);
  };

  const handlePlaybackEnd = () => {
    setIsPlaying(false);
  };

  const handleLogout = () => {
    logout();
    setCurrentUser(null);
    navigate('/login');
  };

  // const handleVideoSource = () => {
  //   setVideoSourceActive(!active);
  // };
  return <div className="home-container" style={{ position: 'relative' }}></div>;
};

export default Home;
